<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  import Vue from 'vue'
  import WebIM from "./utils/WebIM";
    import {SingleLogin} from '@/api/index'
  var _hmt = _hmt || [];
  export default {
    mounted() {
      console.log(this.$route)
      if (this.getUrlParamCN('type')) {
        localStorage.setItem('orderfrom', this.getUrlParamCN('type'))
      } else {
        localStorage.setItem('orderfrom', 'pc')
      }
      if (localStorage.getItem('user')) {
        this.loginIM()
      }
      //axn单点登录
      if(this.getUrlParamCN("axnMobile")){
        var mobile=this.getUrlParamCN("axnMobile");
          var postData={
              "MemberMoblie": mobile,
              "Name": "",
              "Sex": "",
              "MemberComeFrom": 'pc-axn'
          }
          SingleLogin(postData).then(res=>{
                localStorage.setItem('user', JSON.stringify(res.data.UserInfo))
          })
      }

      (function () {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?ea49bebd479ae9b2321fb25134400392";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();

    },
    methods:{
      getUrlParamCN(name) {
          var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
          var r = decodeURI(window.location.search).substr(1).match(reg); //匹配目标参数
          if (r != null) return unescape(r[2]); return null; //返回参数值
      }
    },
    watch: {
      '$route'(to, from) {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
    }
  }

</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  * {
    padding: 0;
    margin: 0;
  }

  input:focus {
    outline: 0;
  }

  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #aaa;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #aaa;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #aaa;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #aaa;
  }

  .el-pagination {
    text-align: center;
    margin-top: 30px;
    color: #aaa;

    .btn-next,
    .btn-prev {
      margin-right: 8px;
      background: rgba(0, 0, 0, 0);
      border: 1px solid #DEDEDE;
      border-radius: 4px;
      padding: 0;
    }

    button {
      min-width: auto;
      width: 30px;
      height: 30px;
    }

    .el-pager li {
      min-width: auto;
      width: 30px;
      height: 30px;
      margin-right: 8px;
      background: rgba(0, 0, 0, 0);
      border: 1px solid #DEDEDE;
      border-radius: 4px;
    }

    .el-pager li.active {
      background: #21D0C8;
      color: #fff;
      border: 1px solid #21D0C8;
    }

    .btn-next:hover,
    .btn-prev:hover,
    .el-pager li:hover {
      border: 1px solid #21D0C8;
      color: #21D0C8;
    }

    .el-pager li.active:hover {
      color: #fff;
    }

    .el-input__inner {
      background: rgba(0, 0, 0, 0);
      border: 1px solid #DEDEDE;
    }

    .el-input__inner:focus {
      border: 1px solid #21D0C8;
    }

    .el-pager li+li {
      border-left: 1px solid #DEDEDE;
    }
  }

  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }

  .el-loading-mask {
    background-color: rgba(0, 0, 0, .2)
  }

</style>
