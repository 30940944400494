import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/index'
  }, {
    path: '/index',
    name: 'index',
    component: ()=>import('../views/index.vue')
  },
  {
    path: '/ask', //我要咨询
    name: 'ask',
    component: ()=>import('../views/ask.vue')
  },
  {
    path: '/login', //登录
    name: 'login',
    component: ()=>import('../views/login.vue')
  },
  {
    path: '/recommend', //推荐律师
    name: 'recommend',
    component: ()=>import('../views/recommend.vue')
  },
  {
    path: '/lawyer', //律师详情
    name: 'lawyer',
    component: ()=>import('../views/lawyer.vue')
  },
  {
    path: '/contract', //合同模板
    name: 'contract',
    component: ()=>import('../views/contract.vue')
  },
  {
    path: '/contractDetail', //合同模板详情
    name: 'contractDetail',
    component: ()=>import('../views/contractDetail.vue')
  },
  {
    path: '/member', //会员
    name: 'member',
    component: ()=>import('../views/member.vue')
  },
  {
    path: '/center', //个人中心
    name: 'center',
    component: ()=>import('../views/center.vue')

  },
  {
    path: '/cashier', //支付
    name: 'cashier',
    component: ()=>import('../views/cashier.vue')
  },
  {
    path: '/download', //客户端下载
    name: 'download',
    component: ()=>import('../views/download.vue')
  },
  {
    path: '/agreement', //用户协议
    name: 'agreement',
    component: ()=>import('../views/agreement.vue')
  },
  {
    path: '/privacy', //隐私协议
    name: 'privacy',
   component: ()=>import('../views/privacy.vue')
  },
  {
    path: '/chatroom', //聊天窗
    name: 'chatroom',
    component: ()=>import('../views/chatroom.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router
