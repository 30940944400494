const Chat = {
    state: {
        newMsg: {},
        chatType:'',//wait待接单正计时,chat聊天中,waitd 定向等待接单,finish:到期
        lawyerThirdId:'',
        noreadtotal:0
    },
    mutations: {
        setNewMsg(state, info) {
            state.newMsg = info;
        },
        setChatType(state, info) {
            state.chatType = info;
        },
        setLawyerThirdId(state, info) {
            state.lawyerThirdId = info;
        },
        setnoreadtotal(state, info) {
            state.noreadtotal = info;
        },
    },
    actions: {
        onNewMsg: function (context, payload) {
            context.commit("setNewMsg", payload);
        },
        onChatType: function (context, payload) {
            context.commit("setChatType", payload);
        },
        onLawyerThirdId: function (context, payload) {
            context.commit("setLawyerThirdId", payload);
        },
         onnoreadtotal: function (context, payload) {
            context.commit("setnoreadtotal", payload);
        },
    },
    getters: {
        onNewMsg(state) {
            return state.newMsg
        },
        onChatType(state) {
            return state.chatType
        },
        onLawyerThirdId(state) {
            return state.lawyerThirdId
        },
        onnoreadtotal(state) {
            return state.noreadtotal
        },
    }

};
export default Chat;