import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import WebIM from './utils/WebIM';
Vue.config.productionTip = false
Vue.use(ElementUI);
import {
  IsMember,CheckMemberIsPinganUser
} from '@/api'
Vue.prototype.isMember = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'))
      IsMember({
        "userID": user.MemberGuid
      }).then(res => {
        if (res.state == 0) {
          if (res.data.isMemberName == 1) {
            user.AssociatorState = '已开通'
            user.AssociatorTimeEnd = res.data.AssociatorTimeEnd
          } else if (res.data.isPurse) {
            user.AssociatorState = '已过期'
          } else {
            user.AssociatorState = '未开通'
            user.AssociatorTimeEnd = null
          }
          localStorage.setItem('user', JSON.stringify(user))
          resolve(user)
        }
      })
    }
  })

}
Vue.prototype.checkPingan = () => {
  return new Promise((resolve, reject) => {
      if (localStorage.getItem('user')) {
        var user = JSON.parse(localStorage.getItem('user'))
        CheckMemberIsPinganUser({
          "memberGuid": user.MemberGuid
        }).then(res => {
          localStorage.setItem('isPingan',res.state)
          resolve(res.state)
        })
      }
  })
}


var _hmt = _hmt || [];
window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到

//统计代码获取
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?98b7a18fe6f3590e7512da920564444d";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();


new Vue({
  router,
  store,
  WebIM,
  render: h => h(App)
}).$mount('#app')
