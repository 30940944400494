import axios from '@/utils/request'
// var baseUrl = 'http://47.104.99.118:8014/api/v3/' //开发

// var baseUrl = 'https://apiptest2.ilvdo.com/api/v3/' //测试
// var paysystem = 'http://47.104.161.85:8358/apppay/goAliPay_QR' //支付宝跳转链接 测试
// var notifyurl = 'https://apipmtest.ilvdo.com/api/AiliPay/NotifyUrl' //支付宝支付回调 测试

var baseUrl = 'https://apiip2.ilvdo.com/api/v3/' //正式
var paysystem='https://pay.ilvdo.com/apppay/goAliPay_QR' //支付宝跳转链接 正式
var notifyurl = 'https://apips.ilvdo.com/api/AiliPay/NotifyUrl' //支付宝支付回调 正式
export {
  notifyurl,
  paysystem
}
//登录
export const SendYZM = (data) => {
  return axios({
    url: baseUrl + 'Login/SendYZM',
    method: 'post',
    data
  })
}
export const AppLogin = (data) => {
  return axios({
    url: baseUrl + 'Login/AppLogin',
    method: 'post',
    data
  })
}
export const SingleLogin = (data) => {
  return axios({
    url: baseUrl + 'Login/SingleLogin',
    method: 'post',
    data
  })
}
export const AppRegister = (data) => {
  return axios({
    url: baseUrl + 'Register/AppRegister',
    method: 'post',
    data
  })
}
export const LoginByMobile = (data) => {
  return axios({
    url: baseUrl + 'Login/LoginByMobile',
    method: 'post',
    data
  })
}
export const CheckMemberIsPinganUser = (data) => {
  return axios({
    url: baseUrl + 'PinganInsurance/CheckMemberIsPinganUser',
    method: 'post',
    data
  })
}
//用户动态
export const GetUserDynamics = () => {
  return axios({
    url: baseUrl + 'HomePage/GetUserDynamics',
    method: 'post',
  })
}
export const GetUserScanRecord = (data) => {
  return axios({
    url: baseUrl + 'Login/GetUserScanRecord',
    method: 'post',
    data
  })
}
//推荐律师
export const GetDayLawyerRecommend = () => {
  return axios({
    url: baseUrl + 'LawyerCard/GetDayLawyerRecommend',
    method: 'post',
  })
}
export const Get_LawyerDetail_WXImg = (data) => {
  return axios({
    url: baseUrl + 'LawyerCard/Get_LawyerDetail_WXImg',
    method: 'post',
    data
  })
}
export const GetChoiceLawyers = () => {
  return axios({
    url: baseUrl + 'LawyerCard/GetChoiceLawyers',
    method: 'post',
  })
}
export const GetPopularLawyer = () => {
  return axios({
    url: baseUrl + 'LawyerCard/GetPopularLawyer',
    method: 'post',
  })
}

//合同模板
export const GetHomeContractTemplates = () => {
  return axios({
    url: baseUrl + 'HomePage/GetHomeContractTemplates',
    method: 'post',
  })
}
export const LawyerServiceInfo = (data) => {
  return axios({
    url: baseUrl + 'LawyerCard/LawyerServiceInfo',
    method: 'post',
    data
  })
}

export const SaveUserScanKey = (data) => {
  return axios({
    url: baseUrl + 'Login/SaveUserScanKey',
    method: 'post',
    data
  })
}
export const GetLawyerServiceRecord = (data) => {
  return axios({
    url: baseUrl + 'LawyerCard/GetLawyerServiceRecord',
    method: 'post',
    data
  })
}
export const CommentListDetail = (data) => {
  return axios({
    url: baseUrl + 'LawyerCard/CommentListDetail',
    method: 'post',
    data
  })
}
//合同模板
export const GetContractTemplates = (data) => {
  return axios({
    url: baseUrl + 'ContractTemplates/GetContractTemplates',
    method: 'post',
    data
  })
}
export const GetContractTemplatesDetails = (data) => {
  return axios({
    url: baseUrl + 'ContractTemplates/GetContractTemplatesDetails',
    method: 'post',
    data
  })
}
export const GetRelationalTemplates = (data) => {
  return axios({
    url: baseUrl + 'ContractTemplates/GetRelationalTemplates',
    method: 'post',
    data
  })
}
export const UpdateLegalDocRecord = (data) => {
  return axios({
    url: baseUrl + 'ContractTemplates/UpdateLegalDocRecord',
    method: 'post',
    data
  })
}
// ************首页****************
export const LawyerProducts = (data) => {
  return axios({
    url: baseUrl + 'LawyerCard/LawyerProducts',
    method: 'post',
    data
  })
}

//判断是否新用户
export const IsNewUser = (data) => {
  return axios({
    url: baseUrl + 'Login/IsNewUser',
    method: 'post',
    data
  })
}
//下单页
export const QuickTextOrder = (data) => {
  return axios({
    url: baseUrl + 'Order/QuickTextOrder',
    method: 'post',
    data
  })
}
export const QuickTelOrder = (data) => {
  return axios({
    url: baseUrl + 'Order/QuickTelOrder',
    method: 'post',
    data
  })
}
export const QuickAssociatorOrder = (data) => {
  return axios({
    url: baseUrl + 'Order/QuickAssociatorOrder',
    method: 'post',
    data
  })
}
export const QuickContractTemplateOrder = (data) => {
  return axios({
    url: baseUrl + 'Order/QuickContractTemplateOrder',
    method: 'post',
    data
  })
}
export const YHList = (data) => {
  return axios({
    url: baseUrl + 'Purse/YHList',
    method: 'post',
    data
  })
}
export const GetCashCouponsList = (data) => {
  return axios({
    url: baseUrl + 'Pay/GetCashCouponsList',
    method: 'post',
    data
  })
}
export const GetConsultationProduct = () => {
  return axios({
    url: baseUrl + 'Product/GetConsultationProduct',
    method: 'post',
  })
}

//聊天
export const GetInfoByThirdIds = (data) => {
  return axios({
    url: baseUrl + 'Chat/GetInfoByThirdIds',
    method: 'post',
    data
  })
}
export const LawyerCommentList = (data) => {
  return axios({
    url: baseUrl + 'LawyerCard/LawyerCommentList',
    method: 'post',
    data
  })
}
export const ServiceInfo = (data) => {
  return axios({
    url: baseUrl + 'Chat/ServiceInfo',
    method: 'post',
    data
  })
}
export const CancelOrderCase = (data) => {
  return axios({
    url: baseUrl + 'Case/CancelOrderCase',
    method: 'post',
    data
  })
}
export const GetSaveNumber = (data) => {
  return axios({
    url: baseUrl + 'Call/GetSaveNumber',
    method: 'post',
    data
  })
}
export const TwoWayCall = (data) => {
  return axios({
    url: baseUrl + 'Call/TwoWayCall',
    method: 'post',
    data
  })
}
export const GetCanCommentOrder = (data) => {
  return axios({
    url: baseUrl + 'Chat/GetCanCommentOrder',
    method: 'post',
    data
  })
}
export const CommentInfo = (data) => {
  return axios({
    url: baseUrl + 'Comment/CommentInfo',
    method: 'post',
    data
  })
}
export const AddComment = (data) => {
  return axios({
    url: baseUrl + 'Comment/AddComment',
    method: 'post',
    data
  })
}
export const UpdateDocument = (data) => {
  return axios({
    url: baseUrl + 'Chat/UpdateDocument',
    method: 'post',
    data
  })
}
export const NoUpdateDocument = (data) => {
  return axios({
    url: baseUrl + 'Chat/NoUpdateDocument',
    method: 'post',
    data
  })
}
export const DirectionalOrder_ls = (data) => {
  return axios({
    url: baseUrl + 'Order/DirectionalOrder_ls',
    method: 'post',
    data
  })
}
export const DirectionalOrder_writing = (data) => {
  return axios({
    url: baseUrl + 'Order/DirectionalOrder_writing',
    method: 'post',
    data
  })
}
export const DirectionalOrder = (data) => {
  return axios({
    url: baseUrl + 'Order/DirectionalOrder',
    method: 'post',
    data
  })
}
//收银台
export const MyOrderDetail = (data) => {
  return axios({
    url: baseUrl + 'MyOrder/MyOrderDetail',
    method: 'post',
    data
  })
}
export const CommonPay = (data) => {
  return axios({
    url: baseUrl + 'Pay/CommonPay',
    method: 'post',
    data
  })
}
export const GetPaymentResult = (data) => {
  return axios({
    url: baseUrl + 'Pay/GetPaymentResult',
    method: 'post',
    data
  })
}
export const IsMember = (data) => {
  return axios({
    url: baseUrl + 'Login/IsMember',
    method: 'post',
    data
  })
}
export const GetCasePrice = () => {
  return axios({
    url: baseUrl + 'MiniProgram/GetCasePrice',
    method: 'post',
  })
}
//会员
export const GetMemberAssociatorPCList = (data) => {
  return axios({
    url: baseUrl + 'Product/GetMemberAssociatorPCList',
    method: 'post',
    data
  })
}

//个人资料
export const GetPersonalInfo = (data) => {
  return axios({
    url: baseUrl + 'UserCenter/GetPersonalInfo',
    method: 'post',
    data
  })
}
export const UpdateUserInfo = (data) => {
  return axios({
    url: baseUrl + 'UserCenter/UpdateUserInfo',
    method: 'post',
    data
  })
}
export const MyOrders = (data) => {
  return axios({
    url: baseUrl + 'MyOrder/MyOrders',
    method: 'post',
    data
  })
}
export const GetMemberAssociatorOrderList = (data) => {
  return axios({
    url: baseUrl + 'MyOrder/GetMemberAssociatorOrderList',
    method: 'post',
    data
  })
}
export const GetUserCenterContractTemplates = (data) => {
  return axios({
    url: baseUrl + 'UserCenter/GetUserCenterContractTemplates',
    method: 'post',
    data
  })
}
export const AppointmentOrders = (data) => {
  return axios({
    url: baseUrl + 'MyOrder/AppointmentOrders',
    method: 'post',
    data
  })
}
export const CaseEntrustmentOrders = (data) => {
  return axios({
    url: baseUrl + 'MyOrder/CaseEntrustmentOrders',
    method: 'post',
    data
  })
}
